<template>
  <div class="step-content-outer">
    <step-header/>
    <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
      <div class="step-content zoom" style="overflow:hidden; height:100vh;margin-top:100px">
        <loading v-if="loading" :loading="loading" />
        <div v-if="!loading" class="container">
          <div class="row justify-content-center">
            <div class="col-8">
              <div class="row">
                <div class="col-md-12">
                  <validation-provider
                    name="Industry"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="input-group-3"
                     
                      label-for="input-3"
                    >
                      <template v-slot:label>
                  {{$t('crt-request.step2.label')}} <span class="required">*</span>
                    </template>

                      <b-form-select
                        :class="{
                          'is-invalid':
                            !getValidationState(validationContext) &&
                            validationContext.errors[0],
                        }"
                        style="margin-bottom: 0 !important"
                        id="industry"
                        v-model="form.industry.id"
                        :options="industrys"
                        value-field="id"
                        text-field="name"
                        placeholder="Enter email"
                        required
                      ></b-form-select>
                      <small class="text-danger">{{
                        validationContext.errors[0]
                      }}</small>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>

              <!--<div class="form-group mb-34">
                <div class="form-group mb-34">
                  <validation-provider
                    name="Industry"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                  >
                    <label for="#">
                      Related Industry
                      <span class="required">*</span>
                    </label>
                    <multi-select
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      name="Industry"
                      id="industry"
                      @close="closeSelect"
                      :openDirection="'bottom'"
                      :searchable="false"
                      v-model="form.industry"
                      :options="industrys"
                    >
                      <template slot="singleLabel" slot-scope="props">
                        <span class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </span>
                      </template>
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">
                            {{ props.option.name }}
                          </span>
                        </div>
                      </template>
                    </multi-select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </validation-provider>
                </div>
              </div>  -->
              <div class="form-group">
                <validation-provider
                  name="Product Name"
                  :rules="{ itemName: true }"
                  v-slot="validationContext"
                >
                  <label for>
                    {{ $t('crt-request.step3.label-1') }}
                    <span class="required mr-3">*</span>
                    <small style="color: c5c5c5">
                      {{ $t('crt-request.step3.label-desc') }}</small
                    >
                  </label>
                  <input spellcheck="true"
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    :placeholder="$t('crt-request.step3.ph1')"
                  />

                  <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                </validation-provider>
              </div>
              <div class="form-group">
                <validation-provider
                  name="units"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <b-form-group
                    id="input-group-3"
                    
                    label-for="input-3"
                  >

                   <template v-slot:label>
                  {{$t('crt-request.step4.label-1')}} <span class="required">*</span>
                    </template>

                    <b-form-select
                      :class="{
                        'is-invalid':
                          !getValidationState(validationContext) &&
                          validationContext.errors[0],
                      }"
                      id="unit"
                      v-model="form.unit.id"
                      :options="units"
                      value-field="id"
                      text-field="name"
                      required
                    ></b-form-select>
                    <small class="text-danger">{{
                      validationContext.errors[0]
                    }}</small>
                  </b-form-group>
                </validation-provider>
                <!--<validation-provider
                  name="units"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label for="#">
                    Unit
                    <span class="required">*</span>
                  </label>
                  <multi-select
                    :class="{
                      'is-invalid':
                        !getValidationState(validationContext) &&
                        validationContext.errors[0],
                    }"
                    name="Industry"
                    id="industry"
                    @close="closeSelect"
                    :openDirection="'bottom'"
                    :searchable="false"
                    v-model="form.unit"
                    :options="units"
                  >
                    <template slot="singleLabel" slot-scope="props">
                      <span class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </span>
                    </template>
                    <template slot="option" slot-scope="props">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ props.option.name }}
                        </span>
                      </div>
                    </template>
                  </multi-select>
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider> -->
              </div>
              <div class="form-group">
                <validation-provider
                  name="demand"
                  :rules="{ required: true }"
                  v-slot="validationContext"
                >
                  <label  for> {{ $t('crt-request.step4.label-2') }}
                     <span class="required">*</span>
                   <small style="margin-left:30px">(ex:100)</small>
                  </label>

                  
                  <input spellcheck="true"
                    v-model.trim="form.demand"
                    type="text"
                    class="form-control"
                  />
                  <small class="text-danger">{{
                    validationContext.errors[0]
                  }}</small>
                </validation-provider>
              </div>
              <div class="form-group mb-34">
                <validation-provider
                  name="desc"
                  :rules="{ itemDescription: true }"
                  v-slot="validationContext"
                >
                  <label for> {{ $t('crt-request.step3.label-2') }}
                     <span class="required">*</span>
                  </label>
                  <textarea spellcheck="true"
                    v-model.trim="form.desc"
                    type="text"
                    rows="7"
                    class="form-control"
                  ></textarea>
                   <div class="counter">
                  <small v-if="validationContext.errors[0]" class="text-danger">
                    {{ validationContext.errors[0] }}
                  </small>
                  <div class="number-back-counter">
                    {{ counter }} / 1200
                  </div>
                  </div>
                </validation-provider>
              </div>
            </div>
          </div>
        </div>

        <!--jimir3x-->
       
        <!-- <div
          class="form-group d-flex align-items-center justify-content-end step3-next-btn-bar step3-next-btn-bar--fixed"
          :class="{
            'justify-content-between': getUserList.list.length > 0,
          }"
        >
       
          <router-link
            v-if="getUserList.list.length > 0"
            :to="'/register-steps/step-3/products'"
            class="d-block mr-2"
          >
            {{ $t('buttons.cancel') }}</router-link
          >
          <button @click="handleSubmit(formSubmit)" class="btn btn-primary">
            {{ $t('buttons.save') }} {{ $t('General.and') }}
            {{ $t('buttons.next') }}
          </button>
        </div> -->
      </div>
      <step-footer v-if="!loading" :disabled="invalid" :skipable="true" @skip="skip()" @submit="handleSubmit(formSubmit)" />
    </validation-observer>
  </div>
</template>

<script>
import StepFooter from '../../components/register-step/step-footer';
import getUnit from '@/graphql/public/getUnit.gql';
import getIndustry from '@/graphql/public/getIndustry.gql';
import addInquiry from '@/graphql/me/addInquiry.gql';
import getInquiries from '@/graphql/me/getInquiries.gql';
import Loading from '@/components/global/loading';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import StepHeader from '@/components/register-step/step-header.vue';
import _ from 'lodash';
export default {
  components: {
    StepFooter,
    Loading,
    StepHeader
  },
  data() {
    return {
      form: {
        name: null,
        demand: null,
        desc: null,
        unit: {
          id: null,
          name: this.$t('crt-request.step4.ph1'),
        },
        industry: {
          id: null,
          name: this.$t('crt-request.step2.ph'),
        },
        active: true,
      },
      loading: true,
      industrys: [],
      units: [],
    };
  },
  methods: {
    ...mapMutations({
      setUserList: 'inquiries/setUserList',
      setCompleteStep: 'general/setCompleteStep'
    }),
    getValidationState({ dirty, validated, valid = null }) {
      if (dirty || validated) {
        return valid;
      }
      return null;
    },
    closeSelect(value, id) {
      console.log(id);
      if (id == 'industry') {
        if (!this.form.industry) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Industry: [msg],
          });
        }
      }
      if (id == 'years') {
        if (!this.form.years) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            years: [msg],
          });
        }
      }
      if (id == 'positions') {
        if (!this.form.position) {
          let msg = this.$t('validations.required', { _field_: id });
          return this.$refs.observer.setErrors({
            Positions: [msg],
          });
        }
      }
    },
    async getUnits() {
      try {
        this.loading = true;
        let data = await this.$apollo.query(getUnit);
        let response = data('**.units');
        this.units = response;

        this.units.unshift({ id: null, name: this.$t('crt-request.step4.ph1') });

        //return this.$router.push("/register-steps/step-1");
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
        this.loading = false;
      }
    },
    async getIndustry() {
      try {
        this.loading = true;
        let par = {
          type: 'PRODUCT_AND_INQUIRY',
        };
        let response = await this.$store.dispatch('inquiries/getIndustries', {
          vm: this,
          payload: par,
        });
        this.industrys = response;

        this.industrys.unshift({
          id: null,
          name: this.$t('crt-request.step2.ph'),
        });
      } catch (e) {
        let msg = this.$options.filters.gqlep(e.message);
        console.log(msg);
      } finally {
        this.loading = false;
      }
    },
    async getInquiries() {
      this.loading = true;
      let params = {
        limit: 10,
        filters: {},
      };
      let data = await this.$apollo.query(getInquiries, params);
      let response = data('**.list');
      this.setUserList(response);
      this.loading = false;
      return response;
    },
    async formSubmit() {
      try {
        this.loading = true;
        let inquiryData = {
          industry_id: this.form.industry.id,
          unit_id: this.form.unit.id,
          demand: parseInt(this.form.demand),
          name: this.form.name,
          description: this.form.desc,
          name: this.form.name,
          active: this.form.active,
        };
        let data = await this.$apollo.mutate(addInquiry, {
          inquiry: inquiryData,
        });
        await this.getInquiries();
        this.$router.push('/register-steps/step-3/products');
      } catch (e) {
      } finally {
        this.loading = false;
      }
    },
    skip() {
        this.setCompleteStep(4);
        return this.$router.push('/register-steps/step-4');
    }
  },
  computed: {
    ...mapGetters({
      getUserList: 'inquiries/getUserList',
    }),
    counter() {
        return _.size(this.form.desc);
    }
  },
  async created() {
    this.getUnits();
    this.getIndustry();
    await this.getInquiries();
  },
};
</script>

<style></style>
